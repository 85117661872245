.backdrop-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 1;
}

.vaFont {
  font-family: Lato;
}
.vaFont17 {
  font-size: 17px; /*13pt */
}
.vaFont17B {
  font-size: 17px; /*13pt B */
  font-weight: bold;
}
.vaFont19B {
  font-size: 19px; /*14pt B */
  font-weight: bold;
}
.vaFont21 {
  font-size: 21px; /*15pt */
}
.vaFont21B {
  font-size: 21px; /*15pt B*/
  font-weight: bold;
}
.vaFont22B {
  font-size: 22px; /*16pt B*/
  font-weight: bold;
}
.vaFont24 {
  font-size: 24px; /*18pt */
}
.vaFont24B {
  font-size: 24px; /*18pt B */
  font-weight: bold;
}
